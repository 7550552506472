<template>
  <div class="background">
    <img :src="SecondaryLogo" class="logo" />
    <button type="button" class="btn" @click="goToRoute()">
      {{ $t("labels.getStarted") }}
    </button>
  </div>
</template>


<script>
import { mapState } from "vuex";
export default {
  name: "LandingPage",
  data() {
    return {
      SecondaryLogo: require("../assets/image/svg/secondaryLogo.svg"),
    };
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    goToRoute() {
      this.$router.push(process.env.VUE_APP_ENABLE_REGISTER === "true" ? "/register" : "/login");
    },
    getPreDataAttempt() {
      let data = {
        flag: true,
        label: "notifications.updating",
      };
      this.$store.dispatch("root/setLoading", data);
    },
    // ...mapActions("root", ["setLoading"]),
  },
  computed: mapState({
    isLoading: (state) => state.root.isLoading,
  }),
};
</script>

<style scoped>
.background {
  height: 100%;
  background-color: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
}
.logo {
  width: 50%;
  height: 300px;
}
.btn {
  margin-top: 50px;
  background-color: var(--pink);
  border-radius: 5px;
  border-width: 1px;
  border-color: "white";
  width: 80%;
  max-width: 300px;
  padding: 10px 0px;
  overflow: hidden;
  cursor: pointer;
  color: white;
  font-size: 15px;
  text-align: center;
}
</style>